jQuery(function ($) {


    const gallery = document.querySelectorAll('.wp-block-gallery');

    gallery.forEach(gallery => {

        const aTags = gallery.querySelectorAll('figure > a');


        //gallery with link
        if (aTags.length > 0) {
            $(aTags).magnificPopup({
                type: 'image',
                gallery: {
                    enabled: true
                },
                image: {
                    titleSrc: function (item) {
                        return item.el.parents('.wp-block-image').find('figcaption').html() || '';
                    }
                }
            });

        } else {
            //gallery without link
            const figures = gallery.querySelectorAll('.wp-block-gallery > figure');

            const items = [];

            let i = 0;

            figures.forEach(figure => {

                const image = figure.querySelector('img');

                const figcaption = figure.querySelector('figcaption');

                const src = image.getAttribute('src');

                const title = (figcaption == null) ? '' : figcaption.innerText;

                items.push({ 'src': src, 'title': title });

                image.setAttribute('data-index', i);

                i++;

            });

            figures.forEach(figure => {

                const image = figure.querySelector('img');

                image.addEventListener('click', e => {

                    const index = parseInt(image.getAttribute('data-index'));

                    $.magnificPopup.open({
                        items: items,
                        type: 'image',
                        gallery: {
                            enabled: true
                        }
                    }, index);
                });
            });
        }

    });




});