jQuery(document).ready(function ($) {
    //selects    
    function customFormElements() {
        $('select').each(function () {
            if ($(this).parents('.custom-select, .address-field').size() == 0) {
                $(this).wrap('<div class="custom-select"></div>');
            }
        });

        $('input:radio, input:checkbox').each(function () {
            if ($(this).next('.custom-checker').size() == 0) {
                $(this).after('<div class="custom-checker" aria-hidden="true"></div>');

                //fix for 'other' radio option in gravityforms
                if ($(this).closest('.gfield_radio').size() > 0 && $(this).siblings('input[type="text"]').size() > 0) {
                    var radio_input = $(this);
                    var text_input = $(this).siblings('input[type="text"]');

                    text_input.removeAttr('onfocus');
                    radio_input.removeAttr('onfocus');
                    radio_input.bind('focus', function () {
                        if (text_input.val() == "Other") {
                            text_input.val("");
                        }
                        text_input.prop('disabled', false);
                        text_input.focus();
                    });
                }
            }
        });

        //custom file upload button
        $('input:file').each(function () {
            if ($(this).parents('.custom-upload').size() == 0 && $(this).parents('.gform_fileupload_multifile').size() == 0) {
                $(this).wrap('<div class="custom-upload"></div>').after('<div class="button">Upload file</div><div class="selected-file"><span class="label">No file chosen</span><span class="clear">Clear</span></div>');
                updateFileInput($(this));
            }
        });
        $(document).on('change', '.custom-upload input:file', function () {
            updateFileInput($(this));
        });
        $(document).on('click', '.custom-upload .clear', function () {
            var container = $(this).closest('.custom-upload');
            container.find('input:file').replaceWith(container.find('input:file').val('').clone(true));
            updateFileInput(container.find('input:file'));
        });

        function updateFileInput(el) {
            el.siblings('.selected-file').find('.label').text(el.val().split(/(\\|\/)/g).pop());

            if (el.val() != '') {
                el.siblings('.selected-file').show();
            } else {
                el.siblings('.selected-file').hide();
            }
        }
    }
    customFormElements();

    //after gform render
    $(document).on('gform_post_render updated_checkout updated_shipping_method updated_wc_div', function () {
        customFormElements();
    });
    //post content image lighbox
    $('.post-content a[href$=".jpg"], .post-content a[href$=".gif"], .post-content a[href$=".png"]').each(function () {
        if ($(this).parents('.blocks-gallery-grid, .wp-block-gallery').size() == 0) {

            $(this).magnificPopup({
                type: 'image',
                zoom: {
                    enabled: true
                },
                image: {
                    titleSrc: function (item) {
                        return item.el.parents('.wp-block-image').find('figcaption').html() || '';
                    }
                }
            });
        }
    });

    //gallery lightbox
    $('.blocks-gallery-grid').each(function () {
        $(this).magnificPopup({
            delegate: 'a',
            type: 'image',
            gallery: {
                enabled: true
            },
            zoom: {
                enabled: true
            },
            image: {
                titleSrc: function (item) {
                    return item.el.parents('.gallery-item').find('.wp-caption-text').html() || '';
                }
            }
        });
    });

    //gallery stacked layout for different sized images
    $('.blocks-gallery-grid:not(.gallery-size-thumbnail)').each(function () {
        var id = $(this).attr('id');

        var cols = 2;
        if ($(this).hasClass('gallery-columns-3')) { cols = 3; }
        if ($(this).hasClass('gallery-columns-4')) { cols = 4; }
        if ($(this).hasClass('gallery-columns-5')) { cols = 5; }
        if ($(this).hasClass('gallery-columns-6')) { cols = 6; }

        $(this).find('br').remove();

        savvior.init('#' + id, {
            "screen and (max-width: 479px)": { columns: 2 },
            "screen and (min-width: 480px)": { columns: cols },
        });
    });

    //videos
    $('.video-link').magnificPopup({
        type: 'iframe'
    });

    /*     //back to top
        $("a.back-to-top").click(function() {
            $("html, body").animate({ scrollTop: 0 }, 300);
            return false;
        }); */

    /*$(window).on("scroll load", function() {
        if($(window).scrollTop() > 200) {
            $("a.back-to-top").addClass("visible");
        } else {
            $("a.back-to-top").removeClass("visible");
        }
    });*/

    //scroll down
    const scrollDownBtn = document.querySelectorAll('.scroll-down');
    scrollDownBtn.forEach(function (btn) {
        btn.addEventListener('click', function (e) {
            e.preventDefault();

            const page_header = document.querySelector('.page-header');
            const header_height = page_header.clientHeight;
            const parent = btn.closest('.banner');
            const distance = parent.offsetTop + parent.clientHeight + header_height

            $("html, body").stop().animate({
                scrollTop: distance
            }, 400);
        })
    })

    //sticky header
    var header_top = $("header.page-header").offset();

    $(window).on("scroll load", function () {

        if ($(window).scrollTop() > header_top.top) {
            $("header.page-header").addClass("fixed");
        } else {
            //remove the background property so it comes transparent again (defined in your css)
            $("header.page-header").removeClass("fixed");
        }
    });

    //announcement message
    var announcement = $('.announcement');

    if (announcement && localStorage.getItem('announcement_dismissed') == null) {
        announcement.addClass('showing');

        announcement.find('.close, .ok').on('click', function () {
            localStorage.setItem('announcement_dismissed', '1');
            announcement.remove();
            return false;
        });
    }

    //hamburger menu
    $('#hamburger-nav li.menu-item-has-children').each(function () {
        $(this).find('> a').after('<span class="expand-arrow" aria-hidden="true"></span>');
    });
    $('#hamburger-nav li.menu-item-has-children.current-menu-item, #hamburger-nav li.menu-item-has-children.current-menu-ancestor').each(function () {
        $(this).find('> .expand-arrow').addClass('expanded');
    });
    $('#hamburger-nav li.menu-item-has-children .expand-arrow').click(function () {
        var li = $(this).closest('li');
        var child_menu = li.children('ul');
        if (child_menu.is(':visible')) {
            li.children('ul').slideUp(200);
            li.find('> .expand-arrow').removeClass('expanded');
        } else {
            li.children('ul').slideDown(200);
            li.find('> .expand-arrow').addClass('expanded');
        }
        return false;
    });
    $('#hamburger-nav li.menu-item-has-children a').click(function (event) {
        event.stopPropagation();
    });

    $('.hamburger.open, .search-link').click(function () {
        $('html').addClass('hamburger-menu-visible');
        $(this).blur();

        if ($(this).is('.search-link') && $('html').hasClass('hamburger-menu-visible')) {
            setTimeout(function () {
                $('#hamburger-menu .searchform input[type="search"]').val('').focus();
            }, 100);
        }

        return false;
    });

    $('#hamburger-menu .hamburger.close').click(function () {
        $('html').removeClass('hamburger-menu-visible');
        $(this).blur();
        return false;
    });

    //site search
    $('#hamburger-menu .searchform').after('<div id="ajax-search-results" class="hidden"></div>');
    var search_form = $('#hamburger-menu .searchform');
    var main_menu = $('#hamburger-nav');
    var search_listing = $('#ajax-search-results');
    var originalUrl = window.location.href;

    $('#hamburger-menu .searchform').submit(function () {
        var link = search_form.attr('action');
        var search_term = search_form.find('input[name="s"]').val();

        //clear
        if (search_form.hasClass('show-results')) {
            search_form.find('input[name="s"]').val('');
            main_menu.removeClass('hidden');
            search_listing.addClass('hidden');
            search_form.removeClass('show-results').removeClass('show-back-button');
            history.pushState(null, null, originalUrl);

            return false;
        }

        //hide all, add loading
        main_menu.addClass('hidden');
        search_form.attr('class', 'searchform loading');
        search_listing.addClass('hidden');

        //load results
        $.post(link + '?s=' + search_term + '&ajax=1', function (data) {
            search_listing.html('').append($(data).find('.search-listing')).removeClass('hidden');
            search_form.removeClass('loading').addClass('show-results show-back-button');
            history.pushState(null, null, link + '?s=' + search_term);
        });

        return false;
    });

    $('#hamburger-menu .searchform').append('<a class="back-link back-to-menu" href="#">Back to menu</a>');
    $('.back-to-menu').on('click', function () {
        search_form.find('input[name="s"]').val('');
        main_menu.removeClass('hidden');
        search_listing.addClass('hidden');
        search_form.removeClass('show-results').removeClass('show-back-button');
        history.pushState(null, null, originalUrl);
        return false;
    });

    //filter
    search_listing.on('click', '.search-filter a', function () {
        var link = $(this).attr('href');

        //hide listing
        search_form.addClass('loading');
        $('.search-filter li').removeClass('current-cat');

        //load results
        $.post(link + '&ajax=1', function (data) {
            search_listing.html('').append($(data).find('.search-listing')).removeClass('hidden');
            search_form.removeClass('loading');
            history.pushState(null, null, link);
        });

        return false;
    });

    //remove clear when new search term is typed
    $('.searchform').find('input[name="s"]').on('input', function () {
        $(this).closest('.searchform').removeClass('show-results');
    });

    //clear for search results page form
    $('.results-searchform').submit(function () {
        if ($(this).hasClass('show-results')) {
            $(this).find('input[name="s"]').val('').focus();
            $(this).removeClass('show-results');
            $(this).siblings('.search-listing, h1').remove();

            return false;
        } else {
            $(this).addClass('loading');
        }
    });

    //footer search
    $('.footer-searchform').submit(function () {
        var searchterm = $(this).find('input[type="search"]').val();

        main_menu.addClass('hidden');
        $('html').addClass('hamburger-menu-visible');

        $('#hamburger-menu .searchform').removeClass('show-results')
            .find('input[type="search"]')
            .val(searchterm)
            .closest('.searchform').submit();

        $('.footer-searchform').find('input[type="search"]').val('');

        return false;
    });

    //clear for blog form
    $('.blog-searchform').submit(function () {
        if ($(this).hasClass('show-results')) {
            window.location.href = $(this).attr('action');
            return false;
        } else {
            $(this).addClass('loading');
        }
    });

    //load more
    $(document).on('click', '.load-more-button .load-more', function () {
        var more_button = $(this);
        var listing = more_button.closest('.load-more-button').prev('.load-more-items');
        var link = more_button.attr('href');

        //show that we're working.
        more_button.text('Loading...');

        $.post(link, function (data) {
            //add loaded items
            listing.append($(data).find('.load-more-item'));

            //update the button
            if ($(data).find('.load-more-button').size() > 0) {
                more_button.closest('.load-more-button').replaceWith($(data).find('.load-more-button'));
            } else {
                more_button.remove();
            }
        });

        return false;
    });

    //cta popup
    $('.button-header-cta').click(function () {

        if ($('#cta-popup-form').length) {

            $('html').toggleClass('cta-popup-visible');
            $(this).blur();

            return false;
        }

    });

    $('#cta-popup-form .hamburger.close').click(function () {
        $('html').removeClass('cta-popup-visible');
        $(this).blur();
        return false;
    });

    //share popup
    $('.share-button').magnificPopup({
        items: {
            src: $('.share-popup:first'),
            type: 'inline'
        },
        mainClass: 'mfp-fade'
    });

    //copy to clipboard  (requires clipboard.js)
    var clipboard = new ClipboardJS('.share-popup .copy');

    //blog listing columns
    savvior.init('.blog-listing .listing', {
        "screen and (max-width: 519px)": { columns: 1 },
        "screen and (min-width: 520px) and (max-width: 899px)": { columns: 2 },
        "screen and (min-width: 900px)": { columns: 3 },
    });

    //skip content bar
    $('.skip-to-content').on('focus', function () {
        $(this).addClass('focus');
    });

    //expand/hide enquiry form below banners
    $('.banner-form .gform_title').on('click', function(){
        let panel = $(this).closest('.banner-form').find('form');

        if(!$(this).hasClass('open')){
            panel.slideDown("fast");
            $(this).addClass('open');
        } else {
            panel.slideUp("fast");
            $(this).removeClass('open');
        }

        $(this).blur();
        return false;
    });

    //owl carousel
    /* $('.slides').owlCarousel({
        items: 1,
        loop: true,
        center: true,
        dots: true,
        nav: true,
        autoplay: true,
        autoplayTimeout: 10000,
        smartSpeed: 1000,
    });*/

});

jQuery(window).load(function () {

    /*jQuery('.slides').flexslider({
        controlNav: false,
        directionNav: false,
        slideshow: true,
        slideshowSpeed: 8000
    });*/

});