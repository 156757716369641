jQuery(function ($) {

    const subpageNavs = document.querySelectorAll('.mobile-subpage-nav');

    if (subpageNavs.length > 0) {

        subpageNavs.forEach((nav) => {

            nav.addEventListener('click', function () {

                nav.classList.toggle('open');

            })
        })
    }
});